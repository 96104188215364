import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Button/Button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Page/Footer.css.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/Page/Page.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/keyless-cookie-sync.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useReverification","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["PromisifiedAuthProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/PromisifiedAuthProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","Waitlist"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FPage%2FFooter.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xOGxmYmYyMCB7CiAgYmFja2dyb3VuZC1jb2xvcjogdmFyKC0tXzFkeWZsZDExbSk7CiAgYm9yZGVyLXRvcDogMXB4IHNvbGlkIHJnYmEoMCwgMCwgMCwgLjA4KTsKICBwYWRkaW5nOiA2ZW0gMS41ZW07Cn0KQG1lZGlhIHByaW50IHsKICAuXzE4bGZiZjIwIHsKICAgIGRpc3BsYXk6IG5vbmU7CiAgfQp9%22%7D");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/legacy/image.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/logo-vertical.png");
